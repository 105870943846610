var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"section"},[_c('base-header',{scopedSlots:_vm._u([{key:"header-table",fn:function(){return [_c('h1',[_vm._v(_vm._s(_vm.name))])]},proxy:true},{key:"item-slot",fn:function(){return [_c('div',{staticClass:"breadcrumb-item"},[_vm._v(_vm._s(_vm.name))])]},proxy:true}])}),_c('section',{staticClass:"body"},[_c('h2',{staticClass:"section-title"},[_vm._v(_vm._s(_vm.name))]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-header"},[_c('h4',[_vm._v(_vm._s(_vm.name)+" Table")]),_c('div',{staticClass:"card-header-form"},[_c('div',{staticClass:"input-group"},[_c('router-link',{staticClass:"btn btn-primary mr-1",attrs:{"to":{ name: 'ProductLadiesCreate' }}},[_vm._v("Create")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.search),expression:"search"}],staticClass:"form-control",attrs:{"type":"text","placeholder":"Search","label":"Search"},domProps:{"value":(_vm.search)},on:{"input":function($event){if($event.target.composing)return;_vm.search=$event.target.value}}}),_vm._m(0)],1)])]),_c('div',{staticClass:"card-body"},[(_vm.loading)?_c('v-progress-circular',{attrs:{"size":50,"color":"primary","indeterminate":""}}):_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.product_category_ladies,"item-key":"id","search":_vm.search,"hide-default-footer":"","page":_vm.page,"items-per-page":_vm.itemsPerPage},on:{"update:page":function($event){_vm.page=$event},"page-count":function($event){_vm.pageCount = $event}},scopedSlots:_vm._u([{key:"item",fn:function(row){return [_c('tr',[_c('td',[_vm._v(_vm._s(row.item.category_ladies))]),_c('td',[_vm._v(_vm._s(row.item.ladies_type))]),_c('td',[_c('router-link',{attrs:{"to":{
                        name: 'ProductShow',
                        params: { id: row.item.product_id },
                      }}},[_vm._v(" "+_vm._s(row.item.product_name)+" ")])],1),_c('td',[_vm._v("Rp. "+_vm._s(_vm.formatPrice(row.item.fee_ladies)))]),_c('td',[_vm._v("Rp. "+_vm._s(_vm.formatPrice(row.item.fee_company)))]),_c('td',[_vm._v("Rp. "+_vm._s(_vm.formatPrice(row.item.fee_agency)))]),_c('td',[_vm._v(_vm._s(row.item.total_agency))]),_c('td',[_vm._v("Rp. "+_vm._s(_vm.formatPrice(row.item.price)))]),_c('td',[_vm._v(_vm._s(row.item.qty))]),_c('td',[_c('router-link',{staticClass:"badge badge-warning mr-1",attrs:{"to":{
                        name: 'ProductLadiesEdit',
                        params: { id: row.item.id },
                      }}},[_vm._v("Edit")]),_c('button',{staticClass:"badge badge-danger",on:{"click":function($event){return _vm.deleteProductLadies(row.item.id)}}},[_vm._v(" Delete ")])],1)])]}}])})],1),_c('div',{staticClass:"card-footer text-right"},[_c('nav',{staticClass:"d-inline-block"},[_c('ul',{staticClass:"pagination mb-0"},[_c('v-pagination',{attrs:{"length":_vm.pageCount},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}})],1)])])])])])])],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"input-group-btn"},[_c('button',{staticClass:"btn btn-primary"},[_c('i',{staticClass:"fas fa-search"})])])
}]

export { render, staticRenderFns }