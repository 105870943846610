<template>
  <section class="section">
    <base-header>
      <template v-slot:header-table
        ><h1>{{ name }}</h1></template
      >
      <template v-slot:item-slot>
        <div class="breadcrumb-item">{{ name }}</div>
      </template>
    </base-header>

    <div class="section-body">
      <base-title></base-title>
      <div class="row">
        <div class="col-12">
          <div class="card">
            <div class="card-header">
              <h4>{{ name }}</h4>
            </div>
            <form
              @submit.prevent="productLadiesInsert"
              enctype="multipart/form-data"
            >
              <div class="card-body">
                <v-progress-circular
                  v-if="loading"
                  :size="50"
                  color="primary"
                  indeterminate
                ></v-progress-circular>
                <div v-else>
                  <div class="form-row">
                    <div class="form-group col-md-6">
                      <label>Product</label>
                      <model-list-select
                        v-if="edit == false"
                        :list="products"
                        v-model="form.product_id"
                        option-value="id"
                        option-text="name"
                        placeholder="Select Product"
                      >
                      </model-list-select>
                      <h4 v-else>{{ form.product_name }}</h4>
                      <small class="text-danger" v-if="errors.product_id">{{
                        errors.product_id[0]
                      }}</small>
                    </div>
                    <div class="form-group col-md-6">
                      <label>Ladies Category</label>
                      <model-list-select
                        :list="ladies_categories"
                        v-model="form.category_ladies_id"
                        option-value="id"
                        option-text="name"
                        placeholder="Select Ladies Category"
                      >
                      </model-list-select>
                      <small class="text-danger" v-if="errors.product_id">{{
                        errors.product_id[0]
                      }}</small>
                    </div>
                    <div class="form-group col-md-6">
                      <label>Ladies Type</label>
                      <model-list-select
                        :list="ladies_types"
                        v-model="form.ladies_type_id"
                        option-value="id"
                        option-text="name"
                        placeholder="Select Ladies Type"
                      >
                      </model-list-select>
                      <small class="text-danger" v-if="errors.ladies_type_id">{{
                        errors.ladies_type_id[0]
                      }}</small>
                    </div>
                    <div class="form-group col-md-6">
                      <label>Quantity</label>
                      <input
                        type="number"
                        class="form-control"
                        v-model="form.qty"
                      />
                      <small class="text-danger" v-if="errors.qty">{{
                        errors.qty[0]
                      }}</small>
                    </div>
                  </div>
                </div>
              </div>
              <div class="card-footer text-right">
                <button
                  class="btn btn-primary mr-1"
                  type="submit"
                  :disabled="isSubmitting"
                >
                  Submit
                </button>
                <button class="btn btn-secondary" type="reset">Reset</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { apiGet, apiPost } from "../../services/api";

export default {
  name: "FormProductCategoryLadies",
  data() {
    return {
      name: "Form Product Category Ladies",
      form: {
        product_id: "",
        qty: "",
        category_ladies_id: "",
        ladies_type_id: "",
      },
      products: [],
      edit: false,
      loading: false,
      ladies_categories: [],
      ladies_types: [],
      errors: {},
      isSubmitting: false,
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    getData() {
      this.loading = true;
      this.edit = false;
      apiGet("product/all/minimal")
        .then((res) => {
          this.products = res.data.data;
          apiGet("ladies_category")
            .then((res) => {
              this.ladies_categories = res.data.data;
              this.loading = false;
              let id = this.$route.params.id;
              if (id) {
                apiGet("product_category_ladies/" + id).then((result) => {
                  this.form = result.data.data;
                  this.edit = true;
                });
              }
            })
            .catch((error) => console.log(error));
          apiGet("ladies_type")
            .then((res) => {
              this.ladies_types = res.data.data;
            })
            .catch((error) => console.log(error));
        })
        .catch((error) => console.log(error));
    },
    productLadiesInsert() {
      this.isSubmitting = true;
      if (this.$route.params.id) {
        apiPost(
          "product_category_ladies/update/" + this.$route.params.id,
          this.form
        )
          .then(() => {
            this.$noty.success("Your Cash Flow has been created!");
            this.$router.push({ name: "ProductLadies" });
          })
          .catch((error) => {
            this.errors = error.response.data;
            this.isSubmitting = false;
          });
      } else {
        apiPost("product_category_ladies/store", this.form)
          .then(() => {
            this.$noty.success("Your Cash Flow has been created!");
            this.$router.push({ name: "ProductLadies" });
          })
          .catch((error) => {
            this.errors = error.response.data;
            this.isSubmitting = false;
          });
      }
    },
  },
};
</script>
