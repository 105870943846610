import ProductLadies from './ProductLadies.vue'
import ProductLadiesCreate from './Create.vue'
import ProductLadiesEdit from './Create.vue'

export default [
    {
        path: '/product_ladies',
        name: 'ProductLadies',
        component: ProductLadies
    },
    {
        path: '/product_ladies/create',
        name: 'ProductLadiesCreate',
        component: ProductLadiesCreate
    },
    {
        path: '/product_ladies/edit/:id',
        name: 'ProductLadiesEdit',
        component: ProductLadiesEdit
    },
]
