<template>
  <section class="section">
    <base-header>
      <template v-slot:header-table
        ><h1>{{ name }}</h1></template
      >
      <template v-slot:item-slot>
        <div class="breadcrumb-item">{{ name }}</div>
      </template>
    </base-header>
    <section class="body">
      <h2 class="section-title">{{ name }}</h2>

      <div class="row">
        <div class="col-12">
          <div class="card">
            <div class="card-header">
              <h4>{{ name }} Table</h4>
              <div class="card-header-form">
                <div class="input-group">
                  <router-link
                    class="btn btn-primary mr-1"
                    :to="{ name: 'ProductLadiesCreate' }"
                    >Create</router-link
                  >
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Search"
                    v-model="search"
                    label="Search"
                  />
                  <div class="input-group-btn">
                    <button class="btn btn-primary">
                      <i class="fas fa-search"></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div class="card-body">
              <v-progress-circular
                v-if="loading"
                :size="50"
                color="primary"
                indeterminate
              ></v-progress-circular>
              <v-data-table
                :headers="headers"
                :items="product_category_ladies"
                item-key="id"
                :search="search"
                hide-default-footer
                :page.sync="page"
                :items-per-page="itemsPerPage"
                class="elevation-1"
                @page-count="pageCount = $event"
                v-else
              >
                <template v-slot:item="row">
                  <tr>
                    <td>{{ row.item.category_ladies }}</td>
                    <td>{{ row.item.ladies_type }}</td>
                    <td>
                      <router-link
                        :to="{
                          name: 'ProductShow',
                          params: { id: row.item.product_id },
                        }"
                      >
                        {{ row.item.product_name }}
                      </router-link>
                    </td>
                    <td>Rp. {{ formatPrice(row.item.fee_ladies) }}</td>
                    <td>Rp. {{ formatPrice(row.item.fee_company) }}</td>
                    <td>Rp. {{ formatPrice(row.item.fee_agency) }}</td>
                    <td>{{ row.item.total_agency }}</td>
                    <td>Rp. {{ formatPrice(row.item.price) }}</td>
                    <td>{{ row.item.qty }}</td>
                    <td>
                      <router-link
                        :to="{
                          name: 'ProductLadiesEdit',
                          params: { id: row.item.id },
                        }"
                        class="badge badge-warning mr-1"
                        >Edit</router-link
                      >
                      <button
                        @click="deleteProductLadies(row.item.id)"
                        class="badge badge-danger"
                      >
                        Delete
                      </button>
                    </td>
                  </tr>
                </template>
              </v-data-table>
            </div>
            <div class="card-footer text-right">
              <nav class="d-inline-block">
                <ul class="pagination mb-0">
                  <v-pagination
                    v-model="page"
                    :length="pageCount"
                  ></v-pagination>
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </section>
  </section>
</template>

<script>
import axios from "axios";
import swal from "sweetalert";
import { apiGet } from "../../services/api";

export default {
  name: "ProductLadies",
  data() {
    return {
      search: "",
      page: 1,
      pageCount: 0,
      itemsPerPage: 10,
      name: "Product Ladies",
      product_category_ladies: [],
      loading: true,
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    getData() {
      this.loading = true;

      apiGet("product_category_ladies")
        .then((res) => {
          this.loading = false;
          this.product_category_ladies = res.data.data;
        })
        .catch((err) => console.log(err));
    },
    deleteProductLadies(id) {
      swal({
        title: "Are you sure?",
        text:
          "Once deleted, you will not be able to recover this imaginary file!",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      }).then((willDelete) => {
        if (willDelete) {
          axios
            .delete(this.$store.state.api + "product_category_ladies/" + id, {
              headers: { Authorization: this.$store.state.token },
            })
            .then(
              () =>
                (this.product_category_ladies = this.product_category_ladies.filter(
                  (v) => {
                    return v.id != id;
                  }
                ))
            )
            .catch((err) => {
              console.log(err);
              swal("Error!", err.message, "error");
            });
          swal("Poof! Your product category file has been deleted!", {
            icon: "success",
          });
        } else {
          swal("Your product category file is safe!");
        }
      });
    },
    formatPrice(value) {
      let val = (value / 1).toFixed(2).replace(".", ",");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
  },
  computed: {
    headers() {
      return [
        {
          text: "Ladies Category",
          value: "category_name",
        },
        {
          text: "Ladies Type",
          value: "ladies_type",
        },
        {
          text: "Product Name",
          value: "product_name",
        },
        {
          text: "Fee Ladies",
          value: "fee_ladies",
        },
        {
          text: "Fee Company",
          value: "fee_company",
        },
        {
          text: "Fee Agency",
          value: "fee_agency",
        },
        {
          text: "Total Agency",
          value: "total_agency",
        },
        {
          text: "Total Price",
          value: "total_price",
        },
        {
          text: "Quantity",
          value: "qty",
        },

        { text: "Action" },
      ];
    },
  },
};
</script>
